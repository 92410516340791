/*=========================================================================================
  File Name: moduleAccState.js
  Description: Acc Module State

==========================================================================================*/

const currentAccDefaults = {
  id: 0,
  name: "",
  companyIndustry: "",
  companyGoal: "",
  createdAt: "",
  status: 0,
  accPlan: {
    id: 0,
    price: 0,
    planParameters: {},
    paymentStatus: 1,
    period: 0,
    endDate: "",
    startTrialDate: "",
    endTrialDate: "",
    plan: {
      id: 0,
      name: "",
      planParameters: {},
      price: 0,
      idSuperlogica: null,
    },
    trialDays: 0,
    isBonus: false,
    superlogicaId: null,
  },
  accIsPaying: false,
  inQueue: false,
  locked: false,
  blockContactTab: false,
  blockContactTabGeneral: false,
  blockDownloadExcel: false,
  smsDispatchEnable: false,
  automationEnable: false,
  smsCredit: 0
};

const state = {
  accounts: [],
  current_acc: currentAccDefaults,
  current_acc_users: [],
  aiFiles: [],
};

export default state;
